function RegisteredPage() {

  return (
    <div className="container">
        <div className='header'>
          <div className="image-div" />
          <h1 className='title'>Spoteet</h1>
        </div>
        <div>
          <p className='subtitle'>Merci à vous !</p> 
          <p>Vous êtes maintenant un ambassadeur de la marque et faîtes parti des premiers à nous faire confiance.</p>
          <p>Nous vous préviendrons quand l’application mobile sera disponible sur les stores.</p>
          <p>A très vite sur Spoteet !</p>
        </div>
    </div>
  );
}

export default RegisteredPage;
