import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {

  const [email, setEmail] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const navigate = useNavigate();

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (isValidEmail(newEmail)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const onSubmit = async () => {
    if (canSubmit) {
      try {
        const response = await fetch('https://api-dev.spoteet.com/users/v1/landing/register-email', {
        //const response = await fetch('http://localhost:8080/users/v1/landing/register-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          navigate('/registered');
        } else {
          console.error('Erreur lors de l\'envoi de l\'email:', response.status, response.body);
        }
      } catch (error) {
        console.error('Erreur réseau:', error);
      }
    }
  };

  return (
    <div className="container">
        <div className='header'>
        <div className="image-div" />

          <h1 className='title'>Spoteet</h1>
        </div>
        <div>
          <p className='subtitle'>Partout, tout le temps, avec tout le monde</p>
          <p>Des talents cachés juste à côté de chez vous !</p>
          <p>Imaginez une app où les artistes partagent en temps réel le spot où ils vont jouer: dans la rue, sur une terrasse, ou dans un bar. </p>
          <p>Spoteet, c’est votre radar pour repérer les meilleurs live autour de vous, suivre vos artistes préférés, et ne plus jamais passer à côté d’un évènement près de chez vous.</p>
          <p>Envie de faire partie des premiers à découvrir Spoteet ?</p>
          <p style={{fontWeight: "bold"}}>Laissez-nous votre email, et soyez aux premières loges dès le lancement de l’app !</p>
          <input type="email" placeholder="Email" 
                        value={email}
                        onChange={onChangeEmail}/><br/>
          <button type="submit" disabled={!canSubmit} onClick={onSubmit}>Rejoindre la liste d'attente</button>
        </div>
    </div>
  );
}

export default HomePage;
