import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home/HomePage';
import RegisteredPage from './pages/Registered/RegisteredPage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/registered" element={<RegisteredPage />} />
      </Routes>
    </Router>
  );

}

export default App;
